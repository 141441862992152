import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectors } from '../features/auth';

export const Home: React.FC = () => {
    const is_authenticated = useSelector(selectors.getIsAuthenticated);
    if (!is_authenticated) {
        return <Redirect to="/login" />;
    }

    return <Redirect to="/dashboard" />;
};
