import { Redirect } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actions as AuthActions } from '../features/auth';

export const Logout = () => {
    const dispatch = useDispatch();

    // dispatching while rendering is a no-no, so delay it until render is complete
    setTimeout(function () {
        dispatch(AuthActions.logout());
    }, 1);

    return <Redirect to="/login" />;
};
