import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect, NavLink } from 'react-router-dom';
import {
    actions as CreditActions,
    selectors as CreditSelectors,
    types as CreditTypes,
} from '../features/credit';
import {
    actions as LeadActions,
    selectors as LeadSelectors,
} from '../features/lead';
import { Page } from '../util/page_wrapper';
import { LeadCredit } from '../components/lead/credit';

import styles from './credit.module.css';

export const LeadReturn = () => {
    const dispatch = useDispatch();
    const { lead_id } = useParams();
    const [isInvalidAccess, setInvalidAccess] = useState(false);
    const leads = useSelector(LeadSelectors.getLeads);
    const credit_info = useSelector(CreditSelectors.getData);

    const leadId = parseInt(lead_id);
    const lead = leads.find((ld) => ld.LeadId === leadId);

    LeadActions.GatedAccess(lead === undefined, leadId, setInvalidAccess);

    if (isInvalidAccess) {
        return <Redirect to="/" />;
    }

    if (!credit_info.isDataReady && !credit_info.isLoadingRequestReasonData) {
        dispatch(CreditActions.load());
    }

    // messages from the server (errors, successfully submitted, etc)
    const header = (
        <>
            <div className="row">
                <div className="col s12">
                    <h2>Lead Return: {leadId}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <NavLink to={'/lead/' + leadId + '/details'}>
                        Back to Lead Details
                    </NavLink>
                </div>
            </div>
            {credit_info.Message.length === 0 ? (
                <></>
            ) : (
                <div className="row">
                    <div
                        className={
                            'col s12 ' +
                            (credit_info.MessageStatus ===
                            CreditTypes.Status.Error
                                ? styles.error
                                : styles.success)
                        }
                    >
                        <h5>{credit_info.Message}</h5>
                    </div>
                </div>
            )}
        </>
    );

    // lead doesn't exist, but isn't invalid.
    // that means it's the initial page load, and we haven't verified it's a good lead yet
    if (!lead || !credit_info.isDataReady) {
        // let the user know which they're waiting for, if only one of them is ready
        const preamble = 'Currently loading: ';
        const waiting_for =
            !lead && !credit_info.isDataReady
                ? ''
                : !lead
                ? preamble + 'lead details.'
                : preamble + 'return reasons.';
        return (
            <>
                {header}
                <div className="row">
                    <div className="col s12">
                        <p>Loading, please wait...</p>
                        <p>{waiting_for}</p>
                    </div>
                </div>
            </>
        );
    }

    // lead has already been returned, so show it's status
    // (pending/rejected) instead of the CreditRequest form
    if (lead.ReturnStatus?.length > 0) {
        return (
            <>
                {header}
                <p>
                    This lead has already been returned, and it's current status
                    is: <strong>{lead.ReturnStatus}</strong>
                </p>
            </>
        );
    }

    // it's a good lead, and this person can see it
    return (
        <Page title={leadId + ' Return'}>
            {header}
            <LeadCredit lead={lead} />
        </Page>
    );
};
