import { ApiRootDev, ApiRootProd } from '../settings';
import { ThunkDispatchType } from './types';
import { actions as AuthActions } from '../features/auth';

export const IsRunningInDevelopment = (): boolean =>
    process.env.NODE_ENV === 'development';

export const GetApiRoot = (): string =>
    IsRunningInDevelopment() ? ApiRootDev : ApiRootProd;

const remoteRequest = (method: string) => {
    return <T>(path: string, dispatch: ThunkDispatchType, payload?: Object) => {
        const request = new Promise<T>((resolve, reject) => {
            fetch(GetApiRoot() + path, {
                method: method,
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .catch(() => {
                    dispatch({
                        type: AuthActions.LOGOUT_USER,
                        payload: 'Connection error.  Please try again.',
                    });
                })
                .then((data) => {
                    if (data && !data.ok && data.status === 401) {
                        const message = 'Session has expired, please log in.';

                        dispatch({
                            type: AuthActions.LOGOUT_USER,
                            payload: message,
                        });

                        reject({ message: message });
                    }

                    if (data) {
                        resolve(data.json());
                    } else {
                        reject({ message: 'No data' });
                    }
                });
        });

        return request;
    };
};

export const getRequest = remoteRequest('get');
export const postRequest = remoteRequest('post');
