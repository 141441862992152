import { types as SearchTypes } from '../lead_search';
import { ActionTypes } from './actions';

export const Binding = 'leads';

export interface LeadDetails {
    isVisibleInSearch: boolean;
    LeadId: number;
    DeliveredDate: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Phone1: string;
    Phone2: string;
    Phone3: string;
    DateOfBirth: string;
    Ssn: string;
    EmploymentMonths: number;
    EmploymentYears: number;
    EmployerName: string;
    JobTitle: number;
    MonthlyIncome: number;
    ResidenceAmount: number | undefined;
    ResidenceYears: number | undefined;
    ResidenceMonths: number | undefined;
    Address1: string;
    Address2: string | undefined;
    CityName: string;
    StateAbbr: string;
    Zip: string;
    ReturnStatus: string;
}

export interface LeadState {
    [Binding]: {
        Leads: LeadDetails[];
    };
}

export const initialState: LeadState = {
    [Binding]: { Leads: [] },
};

interface LeadDataReceivedAction {
    type: typeof ActionTypes.DATA_READY;
    payload: SearchTypes.LeadSearchResponse;
}

export type LeadTypes = LeadDataReceivedAction;
