import React from 'react';
import { types as LeadTypes } from '../../features/lead';

const Container = ({ children }: any) => <div className="row">{children}</div>;
const Header = ({ children }: any) => (
    <div className="col s6">
        <h6>{children}:</h6>
    </div>
);
const FieldValue = ({ children }: any) => (
    <div className="col s6">{children}</div>
);
const Field = ({ label, children }: any) => {
    return (
        <Container>
            <Header>{label}</Header>
            <FieldValue>{children}</FieldValue>
        </Container>
    );
};

const Phone = ({ label, children }: any) => (
    <Field label={label}>
        {children ? <a href={'tel:' + children}>{children}</a> : <span>-</span>}
    </Field>
);
const Email = ({ label, children }: any) => (
    <Field label={label}>
        <a href={'mailto:' + children}>{children}</a>
    </Field>
);
const Ssn = ({ label, children }: any) => {
    const regex = /^(\d{3})(\d{2})(\d{4})$/;
    const match = (children as string).match(regex);
    let value = children;
    if (match && match.length === 4) {
        value = `${match[1]}-${match[2]}-${match[3]}`;
    }
    return <Field label={label}>{value}</Field>;
};

interface Arguments {
    lead: LeadTypes.LeadDetails;
}
export const LeadDetails = ({ lead }: Arguments) => {
    return (
        <>
            <Field label="Lead #">{lead.LeadId}</Field>
            <Field label="First Name">{lead.FirstName}</Field>
            <Field label="Last Name">{lead.LastName}</Field>
            <Field label="Address 1">{lead.Address1}</Field>
            <Field label="Address 2">{lead.Address2}</Field>
            <Field label="City/State">
                {lead.CityName}, {lead.StateAbbr}
            </Field>
            <Field label="Zip Code">{lead.Zip}</Field>
            <Field label="Date of Birth">
                {new Date(lead.DateOfBirth).toLocaleDateString()}
            </Field>
            <Phone label="Phone 1">{lead.Phone1}</Phone>
            <Phone label="Phone 2">{lead.Phone2}</Phone>
            <Phone label="Phone 3">{lead.Phone3}</Phone>
            <Email label="Email">{lead.Email}</Email>
            <Field label="Date Sent">
                {new Date(lead.DeliveredDate).toLocaleDateString()}
            </Field>
            <Ssn label="SSN">{lead.Ssn}</Ssn>
            <Field label="Employment Length">
                Years: {lead.EmploymentYears} Months: {lead.EmploymentMonths}
            </Field>
            <Field label="Employer Name">{lead.EmployerName}</Field>
            <Field label="Job Title">{lead.JobTitle}</Field>
            <Field label="Monthly Income">
                {lead.MonthlyIncome.toFixed(2)}
            </Field>
            <Field label="Residence Amount">
                {lead.ResidenceAmount?.toFixed(2)}
            </Field>
            <Field label="Residence Duration">
                Years: {lead.ResidenceYears || 'unknown'} Months:{' '}
                {lead.ResidenceMonths || 'unknown'}
            </Field>
            <Field label="Credit Report Auth">Yes</Field>
        </>
    );
};
