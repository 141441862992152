import React from 'react';
import { LeadSlug } from './lead/slug';
import { useSelector } from 'react-redux';
import { selectors } from '../features/lead';
import { selectors as SearchSelectors } from '../features/lead_search';
const col_class = 'col s1 hide-on-med-and-down';

export const LeadList = () => {
    const leads = useSelector(selectors.getLeads);
    const search = useSelector(SearchSelectors.getData);
    let row_num = (search.ResultsPage - 1) * (search.Results ?? 10) + 1;
    const first_row = row_num;

    return (
        <>
            <div className="row">
                <div className={col_class}>
                    <h6>#</h6>
                </div>
                <div className={col_class}>
                    <h6>Lead #</h6>
                </div>
                <div className={col_class}>
                    <h6>Date</h6>
                </div>
                <div className={col_class}>
                    <h6>First Name</h6>
                </div>
                <div className={col_class}>
                    <h6>Last Name</h6>
                </div>
                <div className={col_class}>
                    <h6>Email</h6>
                </div>
                <div className={col_class}>
                    <h6>Phone 1</h6>
                </div>
                <div className={col_class}>
                    <h6>Phone 2</h6>
                </div>
                <div className={col_class}>
                    <h6>Phone 3</h6>
                </div>
                <div className={col_class}>
                    <h6>Zip</h6>
                </div>
                <div className={col_class}>
                    <h6>Credit Status</h6>
                </div>
                <div className={col_class}>
                    <h6>Open</h6>
                </div>
            </div>

            {leads.map((lead) => (
                <>
                    {/* don't render the separator before the first row */}
                    {row_num === first_row ? (
                        <></>
                    ) : (
                        <div className="row">
                            <div className="s12 hide-on-large-only">
                                <hr />
                            </div>
                        </div>
                    )}
                    <LeadSlug key={lead.LeadId} row={row_num++} lead={lead} />
                </>
            ))}
        </>
    );
};
