import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { default as _ } from 'underscore';

import { actions, selectors } from '../features/lead_search';

import styles from './searchForm.module.css';

const Field = ({ label, elem }: any) => {
    const [id] = useState(_.uniqueId());

    return (
        <div className="row">
            <div className="col s12 m2">
                <label htmlFor={id}>{label}</label>
            </div>
            <div className="col s12 m10">
                {elem === undefined ? <></> : elem(id)}
            </div>
        </div>
    );
};

export const SearchForm = () => {
    const data = useSelector(selectors.getData);
    const dispatch = useDispatch();

    return (
        <fieldset>
            <label>
                Enter Search Criteria -{' '}
                <button
                    type="button"
                    className={'btn ' + styles.actionBtn}
                    onClick={() =>
                        dispatch(
                            actions.update_field(
                                'isFormCollapsed',
                                !data.isFormCollapsed
                            )
                        )
                    }
                >
                    {data.isFormCollapsed ? 'Show' : 'Hide'} Search Form
                </button>
            </label>

            <form
                onSubmit={(e) => {
                    dispatch(actions.search());
                    e.preventDefault();
                    return false;
                }}
            >
                {data.isFormCollapsed ? (
                    <></>
                ) : (
                    <>
                        <Field
                            label="Start Date"
                            elem={(id: string) => (
                                <input
                                    id={id}
                                    type="date"
                                    value={data.StartDate}
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'StartDate',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="End Date"
                            elem={(id: string) => (
                                <input
                                    id={id}
                                    type="date"
                                    value={data.EndDate}
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'EndDate',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="Lead ID"
                            elem={(id: string) => (
                                <input
                                    id={id}
                                    value={data.LeadID || ''}
                                    placeholder="Lead ID.."
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'LeadID',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="First Name"
                            elem={(id: string) => (
                                <input
                                    value={data.FirstName}
                                    placeholder="First name.."
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'FirstName',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="Last Name"
                            elem={(id: string) => (
                                <input
                                    id={id}
                                    value={data.LastName}
                                    placeholder="Last name.."
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'LastName',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="SSN"
                            elem={(id: string) => (
                                <input
                                    id={id}
                                    value={data.SSN}
                                    placeholder="123-45-6789"
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'SSN',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="Email"
                            elem={(id: string) => (
                                <input
                                    value={data.Email}
                                    placeholder="lead@email.com"
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'Email',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />

                        <Field
                            label="Phone"
                            elem={(id: string) => (
                                <input
                                    type="tel"
                                    value={data.PhoneNumber}
                                    placeholder="555-555-5555"
                                    onChange={(e) =>
                                        dispatch(
                                            actions.update_field(
                                                'PhoneNumber',
                                                e.target.value
                                            )
                                        )
                                    }
                                />
                            )}
                        />
                    </>
                )}

                <div className="row">
                    <div className="col l2 s12">
                        {data.isLoading ? (
                            <button
                                type="button"
                                className={'btn orange ' + styles.fullWidth}
                            >
                                Loading...
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className={
                                    'btn ' +
                                    styles.fullWidth +
                                    ' ' +
                                    styles.actionBtn
                                }
                            >
                                Search
                            </button>
                        )}
                    </div>

                    <div className="col l2 push-l3 s6 center">
                        <div className={styles.btnBaseline}>
                            Page {data.CurrentPage} out of {data.TotalPages}
                        </div>
                    </div>
                    <div className="col l2 s6 push-l6 center">
                        <div className={styles.btnBaseline}>
                            Total Leads: {data.TotalLeads}
                        </div>
                    </div>

                    <div className="col s6 l2 pull-l3">
                        <button
                            type="button"
                            className={'btn ' + styles.fullWidth}
                            disabled={(data.CurrentPage ?? 0) <= 1}
                            onClick={() => {
                                dispatch(
                                    actions.search((data.CurrentPage ?? 0) - 1)
                                );
                            }}
                        >
                            Prev Page
                        </button>
                    </div>

                    <div className="col s6 l2 pull-l1">
                        <button
                            type="button"
                            className={'btn ' + styles.fullWidth}
                            disabled={
                                (data.CurrentPage ?? 0) >= data.TotalPages
                            }
                            onClick={() => {
                                dispatch(
                                    actions.search((data.CurrentPage ?? 0) + 1)
                                );
                            }}
                        >
                            Next Page
                        </button>
                    </div>
                </div>
            </form>
        </fieldset>
    );
};
