import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { Heartbeat } from './components/Heartbeat';
import { RedirectToDetails } from './components/LeadDetailsRedirect';
import { Navbar } from './components/Navbar';

import { Dashboard } from './pages/Dashboard';
import { Home } from './pages/Home';
import { LeadDetails } from './pages/LeadDetails';
import { LeadReturn } from './pages/LeadReturn';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { Footer } from './components/Footer';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Heartbeat />

            <Navbar />
            <main>
                <div className="page">
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/login" component={Login} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/dashboard" component={Dashboard} />

                        <Route
                            path="/lead/:lead_id/credit"
                            component={LeadReturn}
                        />
                        <Route
                            path="/lead/:lead_id/details"
                            component={LeadDetails}
                        />
                        {/* strictly for convenience, redirect /lead/lead_id to the details view */}
                        <Route
                            path="/lead/:lead_id"
                            component={RedirectToDetails}
                        />

                        {/* catchall route, redirect to homepage */}
                        <Route component={() => <Redirect to="/" />} />
                    </Switch>
                </div>
            </main>
            <Footer />
        </BrowserRouter>
    );
};

export default App;
