import React from 'react';
import { types as LeadTypes } from '../../features/lead';
import { NavLink } from 'react-router-dom';

interface SlugType {
    lead: LeadTypes.LeadDetails;
    row: number;
}
interface PhoneProps {
    num: string | null;
}
const Phone = ({ num }: PhoneProps) => {
    if (num && num.length > 0) {
        return <a href={'tel:' + num}>{num}</a>;
    }
    // spacer, so the column doesn't collapse due to not having contents
    return <span>-</span>;
};

export const LeadSlug = ({ lead, row }: SlugType) => {
    const header_class = 'col s4 hide-on-large-only';
    const col_class = 'col s8 l1 truncate';

    return (
        <>
            <div className="row">
                <div className={col_class + ' hide-on-med-and-down'}>{row}</div>

                <div className={header_class}>
                    <strong>Lead ID</strong>
                </div>
                <div className={col_class}>{lead.LeadId}</div>

                <div className={header_class}>
                    <strong>Date</strong>
                </div>
                <div className={col_class}>
                    <div>
                        {new Date(lead.DeliveredDate).toLocaleDateString()}
                    </div>
                </div>

                <div className={header_class}>
                    <strong>First Name</strong>
                </div>
                <div className={col_class}>{lead.FirstName}</div>

                <div className={header_class}>
                    <strong>Last Name</strong>
                </div>
                <div className={col_class}>{lead.LastName}</div>

                <div className={header_class}>
                    <strong>Email</strong>
                </div>
                <div className={col_class}>
                    <a href={'mailto:' + lead.Email}>{lead.Email}</a>
                </div>

                <div className={header_class}>
                    <strong>Phone 1</strong>
                </div>
                <div className={col_class}>
                    <Phone num={lead.Phone1} />
                </div>

                <div className={header_class}>
                    <strong>Phone 2</strong>
                </div>
                <div className={col_class}>
                    <Phone num={lead.Phone2} />
                </div>

                <div className={header_class}>
                    <strong>Phone 3</strong>
                </div>
                <div className={col_class}>
                    <Phone num={lead.Phone3} />
                </div>
                <div className={header_class}>
                    <strong>Zip</strong>
                </div>
                <div className={col_class}>{lead.Zip}</div>
                <div className={header_class}>
                    <strong>Credit Status</strong>
                </div>
                <div className={col_class}>{lead.ReturnStatus ?? '-'}</div>

                <div className={header_class}>
                    <strong></strong>
                </div>
                <div className={col_class}>
                    <NavLink to={'/lead/' + lead.LeadId + '/details'}>
                        Open
                    </NavLink>
                </div>
            </div>
        </>
    );
};
