import React from 'react';
import * as settings from '../settings';

export const Footer = () => (
    <footer className="valign-wrapper">
        <div className="center">
            <div className="line">&copy; 2020 {settings.HeadTitle}.</div>
            <div className="line">All rights reserved.</div>
        </div>
    </footer>
);
