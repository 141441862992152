import React, { useState } from 'react';
import {
    actions as CreditActions,
    selectors as CreditSelectors,
} from '../../features/credit';
import { types as LeadTypes } from '../../features/lead';
import { useSelector, useDispatch } from 'react-redux';

interface Args {
    lead: LeadTypes.LeadDetails;
}

export const LeadCredit = ({ lead }: Args) => {
    const dispatch = useDispatch();
    const data = useSelector(CreditSelectors.getData);

    // flag to make sure we only dispatch a single time
    const [isUpdatingActiveLead, setIsUpdatingActiveLead] = useState(false);
    if (
        !isUpdatingActiveLead &&
        (data.activeLeadId === undefined || data.activeLeadId !== lead.LeadId)
    ) {
        setIsUpdatingActiveLead(true);

        // we get warnings when dispatching before rendering anything,
        // so put this after a small timeout
        setTimeout(() => {
            dispatch(CreditActions.update_field('activeLeadId', lead.LeadId));
        }, 100);
    }

    return (
        <>
            <form
                onSubmit={(e) => {
                    if (!data.isSendingCreditRequest) {
                        dispatch(CreditActions.submit_credit_request());
                    }
                    e.preventDefault();
                    return false;
                }}
            >
                <div className="row">
                    <div className="col s12">
                        <label>
                            Return Reason:
                            <select
                                className="browser-default"
                                value={data.selectedReason_id}
                                onChange={(e) =>
                                    dispatch(
                                        CreditActions.update_field(
                                            'selectedReason_id',
                                            e.target.value
                                        )
                                    )
                                }
                            >
                                <option>
                                    {/* blank options are fun.  don't ruin the fun. */}
                                </option>
                                {data.Reasons.map((rr) => (
                                    <option key={rr.id} value={rr.id}>
                                        {rr.Description}
                                    </option>
                                ))}
                            </select>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <label>
                            Notes:
                            <textarea
                                placeholder="Notes"
                                value={data.Notes}
                                onChange={(e) =>
                                    dispatch(
                                        CreditActions.update_field(
                                            'Notes',
                                            e.target.value
                                        )
                                    )
                                }
                            ></textarea>
                        </label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <button
                            type="submit"
                            className={
                                'btn' +
                                (data.isSendingCreditRequest ? ' orange' : '')
                            }
                        >
                            {data.isSendingCreditRequest
                                ? 'Sending...'
                                : 'Submit Lead For Credit'}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};
