import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as _ from 'underscore';
import { PerformHeartbeat } from '../util/heartbeat';
import { ThunkDispatchType } from '../util/types';

// once every 10 minutes
const heartbeat_delay = 1000 * 60 * 10;
const PingServer = _.debounce(
    (dispatch: ThunkDispatchType, updater: () => void) => {
        PerformHeartbeat(dispatch);

        // make sure we ping again when needed
        setTimeout(updater, heartbeat_delay + 100);
    },
    heartbeat_delay,
    // call immediately, instead of when delay is complete
    true
);

export const Heartbeat = () => {
    const dispatch = useDispatch();
    const [flipflog, setFlipflop] = useState(false);

    PingServer(dispatch, () => setFlipflop(!flipflog));

    return <></>;
};
