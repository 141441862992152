import {
    applyMiddleware,
    combineReducers,
    createStore,
    StoreEnhancer,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { AuthReducer, types as AuthTypes } from './features/auth';
import {
    reducer as CreditReducer,
    types as CreditTypes,
} from './features/credit';
import { reducer as LeadReducer, types as LeadTypes } from './features/lead';
import {
    LeadSearchReducer,
    types as LeadSearchTypes,
} from './features/lead_search';
import { IsRunningInDevelopment } from './util/remote';

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
    [AuthTypes.Binding]: AuthReducer,
    [LeadTypes.Binding]: LeadReducer,
    [LeadSearchTypes.Binding]: LeadSearchReducer,
    [CreditTypes.Binding]: CreditReducer,
});

// only include the dev tools if we're actually in dev
const DevToolsWrapper = IsRunningInDevelopment()
    ? composeWithDevTools
    : (passthru: StoreEnhancer) => passthru;

const store = createStore(
    rootReducer,
    /* preloadedState, */
    DevToolsWrapper(applyMiddleware(ReduxThunk))
);

export default store;
const state = store.getState();
export type StoreType = typeof state;
