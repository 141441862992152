import { postRequest } from '../../util/remote';
import { ThunkActionType } from '../../util/types';
import { Binding } from './types';

export const LOGIN_BEGIN = 'AUTH_USER_LOGIN_START';
export const POST_LOGIN = 'AUTH_USER_LOGIN_COMPLETE';
export const LOGOUT_USER = 'AUTH_USER_LOGOUT';
export const UPDATE_FIELD = 'AUTH_FORM_UPDATE_FIELD';
export const HEARTBEAT_RESPONSE = 'AUTH_USER_HEARTBEAT_RESPONSE';

export function update_field(field: string, value: string | boolean) {
    return {
        type: UPDATE_FIELD,
        field: field,
        payload: value,
    };
}

export function login(): ThunkActionType {
    return function (dispatch, getState) {
        const state = getState();
        const auth = state[Binding];

        dispatch({ type: LOGIN_BEGIN });

        postRequest('/Account/LogOn', dispatch, auth)
            .then((data) =>
                dispatch({
                    type: POST_LOGIN,
                    payload: data,
                })
            )
            .catch(() => {
                // TODO: error handling?
            });
    };
}

export function logout(): ThunkActionType {
    return function (dispatch, getState) {
        postRequest('/Account/LogOff', dispatch)
            .then(() => dispatch({ type: LOGOUT_USER }))
            .catch(() => {
                // TODO: error handling?
            });
    };
}
