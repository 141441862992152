import { useEffect } from 'react';
import { HeadTitle } from '../settings';

export const Page = ({ children, title }: any) => {
    const appendedTitle = title?.length > 0 ? ' - ' + title : '';

    useEffect(() => {
        document.title = HeadTitle + appendedTitle;
    }, [appendedTitle]);

    return children;
};
