import { ThunkDispatchType } from './types';
import { postRequest } from './remote';
import { actions as AuthActions, types as AuthTypes } from '../features/auth';

export const PerformHeartbeat = (dispatch: ThunkDispatchType) => {
    postRequest<AuthTypes.HeartbeatResponse>('/heartbeat/ping', dispatch)
        .then((json) => {
            if (!json.DealerName) {
                // this user is logged in, but doesn't have a dealer
                dispatch({
                    type: AuthActions.LOGOUT_USER,
                    payload: json.Message,
                });
            } else {
                // valid user, update their dealer name so it's visisble
                dispatch({
                    type: AuthActions.HEARTBEAT_RESPONSE,
                    payload: json,
                });
            }
        })
        .catch(() => {
            // TODO: error handling?
        });
};
