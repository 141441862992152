import {
    initialState,
    Binding,
    CreditTypes,
    ActionTypes,
    CreditRequestState,
    Status,
} from './types';

export default (
    state = initialState[Binding],
    action: CreditTypes
): CreditRequestState => {
    switch (action.type) {
        case ActionTypes.NOTIFY_USER:
            return {
                ...state,
                Message: action.payload.message,
                MessageStatus: action.payload.status,
            };
        case ActionTypes.REQUEST_REASONS_START_LOADING:
            return {
                ...state,
                isLoadingRequestReasonData: true,
                isDataReady: false,
            };
        case ActionTypes.REQUEST_REASONS_DATA_READY:
            return {
                ...state,
                isLoadingRequestReasonData: false,
                isDataReady: true,
                Reasons: action.payload,
            };
        case ActionTypes.SUBMIT_CREDIT_REQUEST_START:
            return { ...state, isSendingCreditRequest: true };
        case ActionTypes.SUBMIT_CREDIT_REQUEST_RESPONSE:
            const status = action.payload.IsSuccessful
                ? Status.Ok
                : Status.Error;
            const message =
                action.payload.Message ||
                (action.payload.IsSuccessful
                    ? 'Credit request submitted.'
                    : 'Unknown error.  Please try again.');
            return {
                ...state,
                isSendingCreditRequest: false,
                MessageStatus: status,
                Message: message,
            };
        case ActionTypes.UPDATE_FIELD:
            return {
                ...state,
                Message: '',
                [action.payload.field]: action.payload.value,
            };
        default:
            return state;
    }
};
