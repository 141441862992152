export const Binding = 'credit';

export enum Status {
    Error,
    Ok,
}

export interface CreditRequestSubmissionResponse {
    IsSuccessful: boolean;
    Message: string;
}

export interface CreditRequestReason {
    id: number;
    Description: string;
}

export interface CreditRequestState {
    isDataReady: boolean;
    isLoadingRequestReasonData: boolean;
    isSendingCreditRequest: boolean;
    activeLeadId: number | undefined;
    selectedReason_id?: number;
    Notes: string;
    Message: string;
    MessageStatus: Status;
    Reasons: CreditRequestReason[];
}

interface CreditRequestData {
    [Binding]: CreditRequestState;
}

export const initialState: CreditRequestData = {
    [Binding]: {
        isDataReady: false,
        isLoadingRequestReasonData: false,
        isSendingCreditRequest: false,
        selectedReason_id: undefined,
        activeLeadId: undefined,
        Notes: '',
        Message: '',
        MessageStatus: Status.Ok,
        Reasons: [],
    },
};

export enum ActionTypes {
    REQUEST_REASONS_START_LOADING = 'CREDIT_REQUEST_REASONS_LOAD_START',
    REQUEST_REASONS_DATA_READY = 'CREDIT_REQUEST_REASONS_LOAD_END',
    SUBMIT_CREDIT_REQUEST_START = 'CREDIT_REQUEST_SUBMIT_START',
    SUBMIT_CREDIT_REQUEST_RESPONSE = 'CREDIT_REQUEST_SUBMIT_RESPONSE',
    UPDATE_FIELD = 'CREDIT_FORM_FIELD_UPDATE',
    NOTIFY_USER = 'CREDIT_FORM_USER_NOTIFY',
}

interface UpdateField {
    type: typeof ActionTypes.UPDATE_FIELD;
    payload: {
        field: string;
        value: string;
    };
}
interface StartLoadingReasons {
    type: typeof ActionTypes.REQUEST_REASONS_START_LOADING;
}
interface ReasonsDataReady {
    type: typeof ActionTypes.REQUEST_REASONS_DATA_READY;
    payload: CreditRequestReason[];
}
interface StartSubmittingCreditRequest {
    type: typeof ActionTypes.SUBMIT_CREDIT_REQUEST_START;
}
interface CreditRequestResponseReceived {
    type: typeof ActionTypes.SUBMIT_CREDIT_REQUEST_RESPONSE;
    payload: CreditRequestSubmissionResponse;
}
interface NotifyUser {
    type: typeof ActionTypes.NOTIFY_USER;
    payload: {
        message: string;
        status: Status;
    };
}

export type CreditTypes =
    | NotifyUser
    | StartLoadingReasons
    | UpdateField
    | ReasonsDataReady
    | StartSubmittingCreditRequest
    | CreditRequestResponseReceived;
