import { getRequest, postRequest } from '../../util/remote';
import { ThunkActionType } from '../../util/types';
import {
    ActionTypes,
    Binding,
    CreditRequestReason,
    Status,
    CreditRequestSubmissionResponse,
} from './types';
import { actions as LeadSearchActions } from '../lead_search';

export const load = (): ThunkActionType => {
    return function (dispatch, getState) {
        // inform state we've started loading data
        dispatch({ type: ActionTypes.REQUEST_REASONS_START_LOADING });

        getRequest<CreditRequestReason[]>(
            '/creditrequest/return_request_reasons',
            dispatch
        )
            .catch(() => {
                // TODO: error handling?
            })
            .then((json) => {
                dispatch({
                    type: ActionTypes.REQUEST_REASONS_DATA_READY,
                    payload: json,
                });
            });
    };
};

export const submit_credit_request = (): ThunkActionType => {
    return function (dispatch, getState) {
        const state = getState()[Binding];

        // submissions don't make sense without a reason
        if (state.selectedReason_id === undefined) {
            return dispatch({
                type: ActionTypes.NOTIFY_USER,
                payload: {
                    message: 'Return Reason cannot be left blank.',
                    status: Status.Error,
                },
            });
        }

        dispatch({ type: ActionTypes.SUBMIT_CREDIT_REQUEST_START });

        const request = {
            LeadId: state.activeLeadId,
            RequestReasonId: state.selectedReason_id,
            RequestNotes: state.Notes,
        };
        postRequest<CreditRequestSubmissionResponse>(
            '/creditrequest/submit',
            dispatch,
            request
        )
            .then((json) => {
                dispatch({
                    type: ActionTypes.SUBMIT_CREDIT_REQUEST_RESPONSE,
                    payload: json,
                });

                dispatch(LeadSearchActions.search());
            })
            .catch(() => {
                // TODO: error handling?
            });
    };
};

export const update_field = (
    field: string,
    value: number | string
): ThunkActionType => {
    return function (dispatch, getState) {
        dispatch({
            type: ActionTypes.UPDATE_FIELD,
            payload: { field, value },
        });
    };
};
