import * as Action from './actions';
import { AuthTypes, initialState, AuthState } from './types';

export default (state = initialState.auth, action: AuthTypes): AuthState => {
    switch (action.type) {
        case Action.LOGIN_BEGIN:
            return { ...state, isLoading: true };
        case Action.UPDATE_FIELD:
            return { ...state, [action.field]: action.payload };
        case Action.POST_LOGIN:
            const { Success, Message, DealerName } = action.payload;

            return {
                ...state,
                isLoading: false,
                isLoggedIn: Success,
                remoteSuccess: Success,
                remoteMessage: Message,
                dealerName: DealerName,
            };
        case Action.LOGOUT_USER:
            const message = action.payload || '';
            const remoteSuccess = action.payload === undefined;

            return {
                ...state,
                isLoggedIn: false,
                remoteSuccess: remoteSuccess,
                remoteMessage: message,
            };
        case Action.HEARTBEAT_RESPONSE:
            return {
                ...state,
                remoteMessage: action.payload.Message,
                remoteSuccess: true,
                isLoggedIn: true,
                username: action.payload.UserName,
                dealerName: action.payload.DealerName,
            };
        default:
            return state;
    }
};
