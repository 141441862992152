import { ThunkActionType, ThunkDispatchType } from '../../util/types';
import { postRequest } from '../../util/remote';
import { actions as LeadActions } from '../lead';
import { Binding, LeadSearchResponse, LeadSearchRequest } from './types';

export enum ActionTypes {
    UPDATE_FIELD = 'LeadSearchFieldUpdate',
    START_SEARCH = 'LeadSearchBegin',
    END_SEARCH = 'LeadSearchComplete',
}

export function update_field(field: string, value: string | number | boolean) {
    return {
        type: ActionTypes.UPDATE_FIELD,
        field: field,
        payload: value,
    };
}

export const leadLookup = (
    filters: LeadSearchRequest,
    dispatch: ThunkDispatchType
) => {
    // default to 10 results per page
    filters.Results = filters.Results || 10;
    // default to page 1
    filters.CurrentPage = filters.CurrentPage || 1;

    const promise = postRequest<LeadSearchResponse>(
        '/lead/search',
        dispatch,
        filters
    )
        .then((json) => {
            dispatch({
                type: LeadActions.ActionTypes.DATA_READY,
                payload: json,
            });

            return json;
        })
        .catch(() => {
            // TODO: error handling?
        });

    return promise;
};

export function search(page?: number): ThunkActionType {
    return function (dispatch, getState) {
        dispatch({ type: ActionTypes.START_SEARCH });
        const data: LeadSearchRequest = getState()[Binding];
        // next/prev buttons can override page
        const search_filters = {
            ...data,
            CurrentPage: page ?? data.CurrentPage ?? 1,
        };

        leadLookup(search_filters, dispatch).then((json) => {
            dispatch({
                type: ActionTypes.END_SEARCH,
                payload: json,
            });
        });
    };
}
