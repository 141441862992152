import React, { useState } from 'react';
import { useParams, Redirect, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LeadDetails as Details } from '../components/lead/details';
import {
    actions as LeadActions,
    selectors as LeadSelectors,
} from '../features/lead';
import { Page } from '../util/page_wrapper';

export const LeadDetails = () => {
    const [isInvalidLead, setIsInvalidLead] = useState(false);
    const { lead_id } = useParams();
    const leadId: number = parseInt(lead_id);

    const lead_list = useSelector(LeadSelectors.getLeads);
    const lead = lead_list.find((ld) => ld.LeadId === leadId);

    LeadActions.GatedAccess(lead === undefined, leadId, setIsInvalidLead);

    if (isInvalidLead) {
        return <Redirect to="/" />;
    }

    const header = (
        <>
            <div className="row">
                <div className="col s12">
                    <h2>Lead Details</h2>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <NavLink to="/dashboard">Back to Search Results</NavLink>
                </div>
            </div>
        </>
    );

    if (!lead) {
        return (
            <>
                {header}
                <div className="row">
                    <div className="col s12">
                        <p>Looking up lead info, please wait...</p>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Page title={leadId + ' Details'}>
            {header}
            <Details lead={lead} />
            <div className="row">
                <div className="col s12 center">
                    {lead.ReturnStatus?.length > 0 ? (
                        <div className="red white-text">
                            Credit Request Already Submitted
                        </div>
                    ) : (
                        <NavLink to={'/lead/' + lead.LeadId + '/credit'}>
                            Request Credit
                        </NavLink>
                    )}
                </div>
            </div>
        </Page>
    );
};
