import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, selectors } from '../features/auth';
import { Redirect } from 'react-router-dom';

import styles from './login.module.css';

export const Login: React.FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector(selectors.getAuth);

    const is_logged_in = useSelector(selectors.getIsAuthenticated);
    if (is_logged_in) {
        return <Redirect to="/dashboard" />;
    }

    return (
        <>
            <div className="row">
                <div className="col s12">
                    <h2>Login</h2>
                </div>
            </div>

            {auth.remoteMessage.length === 0 ? (
                ''
            ) : (
                <div className="row">
                    <div className="col s12">
                        <div
                            className={
                                'card ' +
                                (auth.remoteSuccess
                                    ? styles.success
                                    : styles.error)
                            }
                        >
                            <div className="card-content">
                                <span className="card-title">
                                    Login{' '}
                                    {auth.remoteSuccess ? 'Success' : 'Failed'}
                                </span>
                                <p>{auth.remoteMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col s12">
                    <fieldset>
                        <legend>Account Information</legend>

                        <form
                            onSubmit={(e) => {
                                dispatch(actions.login());
                                e.preventDefault();
                                return false;
                            }}
                        >
                            <div className="row">
                                <div className="col s12 m6">
                                    <label>
                                        User Name:
                                        <input
                                            type="text"
                                            value={auth.username}
                                            onChange={(e) =>
                                                dispatch(
                                                    actions.update_field(
                                                        'username',
                                                        e.target.value
                                                    )
                                                )
                                            }
                                        />
                                    </label>
                                </div>
                                <div className="col s12 m6">
                                    <label>
                                        Password:
                                        <input
                                            type="password"
                                            value={auth.password}
                                            onChange={(e) =>
                                                dispatch(
                                                    actions.update_field(
                                                        'password',
                                                        e.target.value
                                                    )
                                                )
                                            }
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={auth.rememberMe}
                                            onChange={() =>
                                                dispatch(
                                                    actions.update_field(
                                                        'rememberMe',
                                                        !auth.rememberMe
                                                    )
                                                )
                                            }
                                        />
                                        <span>Keep Me Logged In</span>
                                    </label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col s12">
                                    {auth.isLoading ? (
                                        <button
                                            type="button"
                                            className="waves-effect btn orange lighten-1"
                                        >
                                            Loading...
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="waves-effect waves-light btn"
                                        >
                                            Login
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </fieldset>
                </div>
            </div>
        </>
    );
};
