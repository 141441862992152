import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { LeadList } from '../components/LeadList';
import { SearchForm } from '../components/SearchForm';
import { selectors as AuthSelectors } from '../features/auth';
import { Page } from '../util/page_wrapper';
import { CreditPolicy } from '../components/CreditPolicy';

export const Dashboard = () => {
    const is_authenticated = useSelector(AuthSelectors.getIsAuthenticated);
    if (!is_authenticated) {
        return <Redirect to="/login" />;
    }

    return (
        <Page title="Lead Search">
            <div className="row">
                <div className="col s12">
                    <h2>Leads</h2>
                </div>
            </div>

            <CreditPolicy />
            <SearchForm />
            <LeadList />
        </Page>
    );
};
