import { ActionTypes } from './actions';
import { getMonthStart } from '../../util/dates';
import { types as LeadTypes } from '../lead';

export const Binding = 'lead_search';

export interface LeadSearchResponse {
    Page: number;
    TotalLeads: number;
    TotalPages: number;
    Leads: LeadTypes.LeadDetails[];
}

export interface LeadSearchRequest {
    Results?: number;
    CurrentPage?: number;
    LeadID?: number | undefined;
    FirstName?: string;
    LastName?: string;
    StartDate?: string;
    EndDate?: string;
    PhoneNumber?: string;
    SSN?: string;
    Email?: string;
}

interface LeadSearchExtension extends LeadSearchRequest {
    isLoading: boolean;
    isFormCollapsed: boolean;
    ResultsPage: number;
    TotalPages: number;
    TotalLeads: number;
}

export interface SearchState {
    [Binding]: LeadSearchExtension;
}

export const initialState: SearchState = {
    [Binding]: {
        isLoading: false,
        isFormCollapsed: false,
        Results: 100,
        CurrentPage: 1,
        ResultsPage: 1,
        TotalPages: 1,
        TotalLeads: 0,

        LeadID: undefined,
        FirstName: '',
        LastName: '',
        StartDate: getMonthStart(),
        EndDate: '',
        SSN: '',
        Email: '',
    },
};

export interface UpdateField {
    type: typeof ActionTypes.UPDATE_FIELD;
    field: string;
    payload: string;
}

export interface StartSearch {
    type: typeof ActionTypes.START_SEARCH;
}
export interface EndSearch {
    type: typeof ActionTypes.END_SEARCH;
    payload: LeadSearchResponse;
}

export type SearchTypes = StartSearch | EndSearch | UpdateField;
