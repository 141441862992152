import React from 'react';
import { NavLink } from 'react-router-dom';
import { selectors as AuthSelectors } from '../features/auth';
import { useSelector } from 'react-redux';

export const Navbar: React.FC = () => {
    const is_authenticated = useSelector(AuthSelectors.getIsAuthenticated);
    const user_name = useSelector(AuthSelectors.getUserName);
    const dealer_name = useSelector(AuthSelectors.getDealerName);

    return (
        <header>
            <section>
                <div className="row">
                    <div className="col s12 center">
                        <h1>
                            <NavLink to="/" className="brand-logo">
                                <div className="col s12 hide-on-med-and-up">
                                    First Choice
                                </div>{' '}
                                <div className="col s12 hide-on-med-and-up">
                                    Auto Leads
                                </div>
                                <div className="col s12 center-align hide-on-small-only">
                                    First Choice Auto Leads
                                </div>
                            </NavLink>
                        </h1>
                    </div>

                    <div className="col s12 center">
                        <h2 className="dealer-name">
                            {is_authenticated ? dealer_name : ''}
                        </h2>
                    </div>
                </div>
            </section>

            <nav>
                <ul className="center">
                    <li cy-data="home-nav-link">
                        <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                        {is_authenticated ? (
                            <NavLink to="/logout">
                                [{user_name}] - Logout
                            </NavLink>
                        ) : (
                            <NavLink to="/login">Login</NavLink>
                        )}
                    </li>
                </ul>
            </nav>
        </header>
    );
};
