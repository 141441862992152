import * as Action from './actions';

export const Binding = 'auth';

export interface AuthState {
    isLoading: boolean;
    isLoggedIn: boolean;
    dealerName: string;
    username: string;
    password: string;
    rememberMe: boolean;
    remoteMessage: string;
    remoteSuccess: boolean;
}

export interface AuthStore {
    [Binding]: AuthState;
}

export const initialState: AuthStore = {
    [Binding]: {
        isLoading: false,
        isLoggedIn: false,
        dealerName: '',
        username: '',
        password: '',
        rememberMe: false,
        remoteMessage: '',
        remoteSuccess: false,
    },
};

export interface HeartbeatResponse {
    Message: string;
    UserName: string;
    DealerName: string;
}

interface LoginActionBegin {
    type: typeof Action.LOGIN_BEGIN;
}

interface LogoutAction {
    type: typeof Action.LOGOUT_USER;
    // message from the server (expired session)
    payload: string;
}

interface LoginActionComplete {
    type: typeof Action.POST_LOGIN;
    payload: {
        Success: boolean;
        Message: string;
        DealerName: string;
    };
}

interface UpdateFormAction {
    type: typeof Action.UPDATE_FIELD;
    field: string;
    payload: string | boolean;
}

interface HeartbeatResponseAction {
    type: typeof Action.HEARTBEAT_RESPONSE;
    payload: HeartbeatResponse;
}

export type AuthTypes =
    | UpdateFormAction
    | LoginActionBegin
    | LoginActionComplete
    | LogoutAction
    | HeartbeatResponseAction;
