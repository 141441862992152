import { initialState, Binding, LeadTypes } from './types';
import { ActionTypes } from './actions';

export default (state = initialState[Binding], action: LeadTypes) => {
    switch (action.type) {
        case ActionTypes.DATA_READY:
            return { ...state, Leads: action.payload.Leads };
        default:
            return { ...state };
    }
};
