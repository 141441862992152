import React from 'react';

import './creditpolicy.module.css';

// TODO: Link to full lead return policy
export const CreditPolicy = () => (
    <p>
        <em>
            Requests for credit invalid credit applications{' '}
            <strong>MUST</strong> BE submitted within 7 calendar days of receipt
            of the applicable application, <strong>EXCEPT</strong> those
            applications sent to dealer during the last 5 days of any calendar
            month, which requests must be submitted no later than 2 business
            days after such calendar month <a href="/">Click Here</a> for full
            invalid lead return policy.
        </em>
    </p>
);
