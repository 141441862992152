import { initialState, SearchTypes } from './types';
import { ActionTypes } from './actions';

export default (state = initialState.lead_search, action: SearchTypes) => {
    switch (action.type) {
        case ActionTypes.UPDATE_FIELD:
            // if any search field is updated, reset to page
            const CurrentPage =
                action.field === 'CurrentPage' ? action.payload : 1;
            return {
                ...state,
                CurrentPage: CurrentPage,
                [action.field]: action.payload,
            };
        case ActionTypes.START_SEARCH:
            return { ...state, isLoading: true };
        case ActionTypes.END_SEARCH:
            return {
                ...state,
                isLoading: false,
                isFormCollapsed: true,
                CurrentPage: action.payload.Page,
                ResultsPage: action.payload.Page,
                TotalPages: action.payload.TotalPages,
                TotalLeads: action.payload.TotalLeads,
            };
        default:
            return state;
    }
};
