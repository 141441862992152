import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as SearchActions } from '../lead_search';

export enum ActionTypes {
    DATA_READY = 'LEAD_DATA_RECEIVED',
}

const _Callback = (newValue: boolean): void => {};
type InvalidAccessCallback = typeof _Callback;

/**
    Used for direct-url access, to make sure the lead attempted to be loaded is valid for this user

    @param lookupNeeded
        Whether or not any lookup/validation is needed.
        Required becase `useEffect()` must run every render
    @param leadId
        The lead to lookup.
    @param setInvalidAccess
        Callback to invorm view that the lead is invalid for this user.
        If this fires, best to just <Redirect to="/" /> instead of showing error messages.
*/
export const GatedAccess = (
    lookupNeeded: boolean,
    leadId: number,
    setInvalidAccess: InvalidAccessCallback
) => {
    const dispatch = useDispatch();
    const [lookupStarted, setLookupStarted] = useState(false);

    useEffect(() => {
        if (lookupNeeded) {
            if (!lookupStarted) {
                // probably using a direct link to this lead that wasn't already loaded via the search
                // so find the lead's info
                SearchActions.leadLookup({ LeadID: leadId }, dispatch)
                    // finally, because we don't care if is was successful or not
                    // if it fails, and the user isn't logged in, the view can handle that.
                    // We just need to let the view know it needs to update, so it has a chance to handle it
                    .finally(() => setLookupStarted(true));
            } else {
                // attempt to look up a lead that isn't for this dealer
                setInvalidAccess(true);
            }
        }
    }, [lookupNeeded, dispatch, lookupStarted, leadId, setInvalidAccess]);
};
